import { createStore } from 'redux';

const initialState = {
  // dominio:
  //   'https://allsafe-mexico.com/ITQ_/eventos_aula/gestorEventosAPI/public',
  // dominioImg:
  //   'https://allsafe-mexico.com/ITQ_/eventos_aula/gestorEventosAPI/assets',

  // dominio: 'https://38.65.128.116/gestoreventos/gestorEventosAPI/public',
  // dominioImg: 'https://38.65.128.116/gestoreventos/gestorEventosAPI/assets',

  dominio:
    'https://prefectos.queretaro.tecnm.mx/gestoreventos/gestorEventosAPI/public',
  dominioImg:
    'https://prefectos.queretaro.tecnm.mx/gestoreventos/gestorEventosAPI/assets',

  // dominio: 'http://localhost:8081/gestorEventosAPI/public',
  // dominioImg: 'http://localhost:8081/gestorEventosAPI/assets',

  //Permisos de usuario
  permisoAcceso: null,
  permisoEventos: null,
  permisoReportes: null,
  permisoCrearUsuarios: null,
  permisoCrearProfesores: null,
  permisoCrearAulas: null,

  //Filtros generales de las tablas de todos los apartados
  filtroGeneral: '',
  paginacionGeneral: 1,
  resultadosPaginaGeneral: 5,

  //Filtros especificos del apartado reporte
  idPlanFiltroReporte: '',
  idAulFiltroReporte: '',
  idProfFiltroReporte: '',
  idEveFiltroReporte: '',
  fechaIniRepFiltroReporte: '',
  fechaFinRepFiltroReporte: '',

  //Filtros especificos del apartado profesor
  nombreFiltroProfesor: '',
  rfcFiltroProfesor: '',
  estatusFiltroProfesor: 'activos',

  //Filtros especificos del apartado usuario
  usuarioFiltroUsuario: '',
  emailFiltroUsuario: '',
  idTipoUsuFiltroUsuario: '',
  estatusFiltroUsuario: 'activos',

  //Filtros especificos del apartado aula
  aulaFiltroAula: '',
  idPlanFiltroAula: '',
  estatusFiltroAula: 'activos',

  //Filtros especificos del apartado horario
  idPlanFiltroHorario: '',
  idAulFiltroHorario: '',
  idProfFiltroHorario: '',
  idDiaFiltroHorario: '',
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'restartTodosFiltros':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        resultadosPaginaGeneral: 5,

        //Filtros del apartado reporte
        idPlanFiltroReporte: '',
        idAulFiltroReporte: '',
        idProfFiltroReporte: '',
        idEveFiltroReporte: '',
        fechaIniRepFiltroReporte: '',
        fechaFinRepFiltroReporte: '',

        //Filtros del apartado profesor
        nombreFiltroProfesor: '',
        rfcFiltroProfesor: '',
        estatusFiltroProfesor: 'activos',

        //Filtros del apartado usuario
        usuarioFiltroUsuario: '',
        emailFiltroUsuario: '',
        idTipoUsuFiltroUsuario: '',
        estatusFiltroUsuario: 'activos',

        //Filtros especificos del apartado aula
        aulaFiltroAula: '',
        idPlanFiltroAula: '',
        estatusFiltroAula: 'activos',

        //Filtros especificos del apartado horario
        idPlanFiltroHorario: '',
        idAulFiltroHorario: '',
        idProfFiltroHorario: '',
        idDiaFiltroHorario: '',
      };
    case 'restablecerFiltrosApartados':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,

        //Filtros del apartado reporte
        idPlanFiltroReporte: '',
        idAulFiltroReporte: '',
        idProfFiltroReporte: '',
        idEveFiltroReporte: '',
        fechaIniRepFiltroReporte: '',
        fechaFinRepFiltroReporte: '',

        //Filtros del apartado profesor
        nombreFiltroProfesor: '',
        rfcFiltroProfesor: '',
        estatusFiltroProfesor: 'activos',

        //Filtros del apartado usuario
        usuarioFiltroUsuario: '',
        emailFiltroUsuario: '',
        idTipoUsuFiltroUsuario: '',
        estatusFiltroUsuario: 'activos',

        //Filtros especificos del apartado aula
        aulaFiltroAula: '',
        idPlanFiltroAula: '',
        estatusFiltroAula: 'activos',

        //Filtros especificos del apartado horario
        idPlanFiltroHorario: '',
        idAulFiltroHorario: '',
        idProfFiltroHorario: '',
        idDiaFiltroHorario: '',
      };
    case 'restartPaginacion':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
      };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
