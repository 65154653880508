import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './scss/style.scss';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

//Pages
const Login = React.lazy(() => import('./views/login/Login'));

const App = () => {
  return (
    <BrowserRouter basename='/gestoreventos/build'>
      {/* basename='/ITQ_/eventos_aula/build' */}
      {/* "homepage" : "https://allsafe-mexico.com/ITQ_/eventos_aula/build", */}
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path='/login'
            name='Login Page'
            render={(props) => <Login {...props} />}
          />
          <Route
            path='/'
            name='Home'
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
